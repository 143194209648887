import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Button,
  // Label,
  Modal,
  ModalContent,
  // ModalButtons,
  Icon
} from 'mw-style-react';
import cn from 'classnames';
import './Download.scss';
import Utils from '../../utils/utils';
import Clients from '../../components/Clients/Clients';
import UtilsApi from '../../utils/api';
import { Link } from '../../i18n';
import Image from '../../components/Image/Image';
// import iconArrow from '../../images/arrow.svg';
const host = process.env.GATSBY_HOST;

class Download extends PureComponent {
  constructor(props) {
    super(props);
    let login = '';
    if (typeof window !== 'undefined') {
      login = localStorage.getItem('userEmail');
    }

    this.state = {
      load: false,
      fields: props.data.form.fields,
      errors: [],
      isAuth: false,
      userEmail: login,
      DownloadItem: ''
    };
  }


  componentDidMount() {
    const { isAuth } = this.state;
    if (!isAuth) {
      UtilsApi.isAuth((res) => {
        if (res.login) {
          this.setState({
            isAuth: true,
            userEmail: res.login,
            name: res.nick
          });
          // автоматичпеское скачивание после редиректа обратно
          if (typeof window !== 'undefined' && window.location.hash) {
            const hash = window.location.hash.slice(1).split('=');
            const field = hash[0];
            const key = hash[1];
            const { downloadList } = this.props.data;
            if (field === 'download' && key) {
              window.location.hash = '';
              const item = downloadList.find(item => (item.key === key));
              this.sendInvite(
                this.props.data.form.button.link,
                key,
                [
                  {
                    key: 'email',
                    value: res.login
                  },
                  {
                    key: 'name',
                    value: res.nick
                  },
                  {
                    key: 'type',
                    value: key
                  },
                ]
              );

              this.download(item, item.link);
            }
          }
        }
      });
    }
  }

  sendInvite(link, type, fields) {
    const { userEmail, name } = this.state;
    const body = {
      action: 'download',
      fields: [
        {
          key: 'email',
          value: userEmail
        },
        {
          key: 'name',
          value: name
        },
        {
          key: 'type',
          value: type
        },
      ],
      form_id: 'download'
    };
    if (fields) {
      body.fields = fields;
    }
    UtilsApi.sendForm(body, link, () => {
      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', 'submit', {
          event_category: 'inbound',
          event_label: window.location.pathname,
        });
      }
    });
  }

  download(item, link) {
    if (item.value === 'VMware' && typeof window !== 'undefined') {
      window.location.href = link;
    }

    this.setState({
      DownloadItem: item
    });
  }

  back() {
    this.setState({
      DownloadItem: ''
    });
  }

  renderBtn(item, form, isAuth, intl, value, key) {
    const btnText = item.key === 'vmware' ? 'downloadButtonText' : 'downloadButtonTextGet';
    if (item.disabled) {
      return (
        <Button
          className="button__default download__btn disabled"
          label={intl.formatMessage({id: form.button.textDisabled})}
          size={'small'}
        />
      );
    } else if (isAuth) {
      return (
        <div
          onClick={() => this.download(item, item.link)}
        >
          <Button
            className="button__default download__btn"
            label={intl.formatMessage({ id: btnText })}
            size={'small'}
            onClick={() => this.sendInvite(form.button.link, key)}
            id={item.id}
          />
        </div>
      );
    }

    let redirectLink = `https://corezoid.com/download/#download=${key}`;
    if (typeof window !== 'undefined') {
      redirectLink = `${window.location.origin}${window.location.pathname}#download=${key}`;
    }

    return (
      <Link to={`${host}/auth2/single_account?redirect_uri=${encodeURIComponent(redirectLink)}`}>
        <Button
          className="button__default download__btn"
          label={intl.formatMessage({id: btnText})}
          size={'small'}
        />
      </Link>
    );
  }

  renderList(linkInstruction) {
    const { intl, data } = this.props;
    const {
      form,
      downloadList,
    } = data;
    const { isAuth } = this.state;

    return (
      downloadList.map((item, index) => (
        <div key={index} className={cn('download__item__wrap', `${item.value}`)}>
          <div className="download__item">
            <div className="download__info">
              <div className="download__info__img__wrap">
                <Image className="download__info__img" file={item.image} />
              </div>
              <div className="download__info__version">
                {`${item.version}`}
              </div>
            </div>
            {this.renderBtn(item, form, isAuth, intl, item.value, item.key)}
            <div className={'download__info__helpLinks'}>
              {item.helpLinks ?
                item.helpLinks.map((l, index) => (
                  <Link
                    key={index}
                    className={'download__info__helpLink'}
                    to={l.link}
                  >
                    {intl.formatMessage({id: l.value})}
                  </Link>
                )) :
                <div className={'download__info__helpLink'}>{intl.formatMessage({id: linkInstruction})}</div>
              }
            </div>
          </div>
        </div>
      ))
    );
  }

  renderModal(DownloadItem, text) {
    const { intl } = this.props;
    const value = DownloadItem ? DownloadItem.value : '';
    const link = DownloadItem ? DownloadItem.link : '';
    const textWithEmail = text.replace('{type}', DownloadItem.value).replace('{link}', link);
    const textThank = intl.formatMessage({id: 'downloadThank'});
    if (typeof window !== 'undefined') {
      return (
        <div
          style={{display: value ? 'flex' : 'none'}}
          className="download__modal"
          onClick={() => this.back()}
        >
          <Modal
            visibility={value}
            size={'medium'}
          >
            <ModalContent>
              <div>
                <Icon className="download__item__close" type={'close'} size={'medium'} onClick={() => this.back()}/>
                {
                  value === 'VMware' ?
                    <h2
                      className="download__item__done"
                      dangerouslySetInnerHTML={{
                        __html: `${Utils.bbCodeToHtml(textWithEmail)}`
                      }}
                    /> :
                    <h2
                      className="download__item__done"
                      dangerouslySetInnerHTML={{
                        __html: `${Utils.bbCodeToHtml(textWithEmail + intl.formatMessage({id: 'downloadLink'}))} <a href=${link} target='_blank'>${intl.formatMessage({id: 'downloadHere'})}.</Link>`
                      }}
                    />
                }
                <h2
                  className="download__item__done"
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(textThank)
                  }}
                />
              </div>
            </ModalContent>
            {/* <ModalButtons>
              <Button
                className="button__default"
                label={intl.formatMessage({id: 'downloadBack'})}
                size="smallplus"
                // type="text"
                onClick={() => this.back()}
                // onClick={handleSubmit}
                // visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </ModalButtons>*/}
          </Modal>
        </div>
      );
    }
  }

  render() {
    const { intl, data } = this.props;
    const {
      title,
      subTitle,
      links,
      linksTitle,
      linkInstruction,
      form
    } = data;

    const { DownloadItem, userEmail } = this.state;
    const text = intl.formatMessage({id: form.downloadText}).replace('{email}', userEmail);

    return (
      <div>
        <div className="download__wrapper">
          <div className="grid-wrapper download">
            <div className="download__title">
              <h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: title}))
                  }}
                />
              </h1>
              <h2>
                {intl.formatMessage({id: subTitle})}
              </h2>
            </div>
            <div className="download__logo">
              <Image file={'middleware-logo.svg'} />
            </div>
            <div className="download__items">
              {this.renderList(linkInstruction)}
            </div>
            <div className="download__links">
              <div className="download__links__title">{intl.formatMessage({id: linksTitle})}</div>
              <span
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: links}))
                }}
              />
            </div>
          </div>
        </div>
        <Clients background={'#fff'}/>
        {this.renderModal(DownloadItem, text, form)}
      </div>
    );
  }
}


Download.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Download);

